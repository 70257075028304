<template lang="pug">
  tr.group(@click="$emit('click')")

    td.group__title
      v-icon(
        color="white"
        :class="{'expanded': isExpanded}"
        data-test="candidate-open"
      ) mdi-chevron-down
      span {{ item.title }} {{ item.title | DOWDutchWithFormatting }}
      portal-target(:name="`course-list-progress-bar-${item.value}`")
    td
    td
    td
    td
    td
    td
    td
    td
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
      },
      isExpanded: {
        type: Boolean,
        default: false
      },
    },

  }
</script>

<style lang="scss" scoped>
  .group{
    background-color: $primary-color;

    &:hover{
      background-color: $primary-color !important;
    }

    &__title{
      display: flex;
      align-items: center;
      position: absolute;
      padding-left: 16px;
      color: white;
      height: 48px;
      font-size: 18px;
      width: calc(100% - 32px);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      cursor: pointer;

      span{
        margin-left: 8px;
        margin-right: 16px;
      }
    }
    td{
      background-color: $primary-color;
    }

    .expanded {
      transition: .2s;
      transform: rotate(-180deg);
    }
  }
</style>
